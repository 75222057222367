// Provide translation for static texts 
export const i18nStrings = {
    card_link: {
        fr: ["Voir liste de", "items"],
        en: ["See list of", "items"],
    },
    link: {
        fr: "Voir sur",
        en: "See on",
    },
    price: {
        fr: "A partir de",
        en: "Starting",
    },
    sponsored: {
        fr: "Sponso",
        en: "Partner",
    },
    switchLang: {
        fr: "Read in english here",
        en: "Lire en français ici",
    },
    curator: {
        fr: "Curateur",
        en: "Curator",
    },
    byCurators: {
        fr: "Curation par",
        en: "Curated by",
    },
    mailTitle: {
        fr: "Tu vas adorer cette liste",
        en: "You'll love this list",
    },
}