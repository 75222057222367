// Return languages available for a list based on existence of translated slug
// Order in the array determines the default (we'll set the first one by default)
export const getLanguages = (list) => {
    let languages = []
    // if english exists, it will be put first in the array (default)
    if ( list.slug ) { languages.push('en') }
    if ( list.slug_fr ) { languages.push('fr') }
    return languages
}

// Returns the name of the field based on language namespace
// Takes the name of the key, and adds language namespace if needed
export const translateKey = (cardLang, key) => {
    if ( cardLang === 'en' ) return `${key}`
    if ( cardLang === 'fr' ) return `${key}_fr`
}

// Returns a link without protocol and hash
// Takes a link
export const cleanLink = (link) => {
    if (link) {
        let sanitized = link.replace(/(https?:\/\/|w{3})/, "")
        sanitized = sanitized.search(/\//) !== -1 ? sanitized.slice(0, sanitized.search(/\//)) : sanitized
        // console.log('SANI', sanitized.search(/\//))
        return sanitized
    } else {
        return null
    }
}